import styled from '@emotion/styled';
import { CloseOutlined } from '@mui/icons-material';
import { Button, Form, Row, Select } from 'antd';
import { getRequest, postRequest, ResponseDataType } from 'api';
import { Avatar, Popup, popupStyles } from 'Components';
import { useAppContext } from 'Context/AppContext';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getFullInfoInDepartment, modalConfirm, normalizeText } from 'utils';

interface AddFollowerPopupPropsType {
  close: () => void;
}

interface MemberDepartmentInfo {
  name: string;
  namecode: string;
  isManager: boolean;
}

interface MemberInfoInList {
  id: string;
  name: string;
  avatar: string;
  departmentInfo: MemberDepartmentInfo;
}

export const AddFollowerPopup = (props: AddFollowerPopupPropsType) => {
  const { close } = props;
  const [form] = Form.useForm();
  const [memberList, setMemberList] = useState<MemberInfoInList[]>([]);
  const { currentOrg } = useAppContext();
  const { streamId: rootId } = useParams();

  useEffect(() => {
    const getMemberList = async () => {
      const response: ResponseDataType<{
        managerList: string[];
        memberList: MemberInfoInList[];
      }> = await getRequest(
        `/organization/get-org-member-list?orgId=${currentOrg}&type=all&getPosition=true`,
      );

      if (!response.data) {
        setMemberList([]);
        return;
      }

      const resData = response.data;
      const sortedMemberList = resData.memberList.reduce(
        (curList: MemberInfoInList[], member) => {
          if (resData.managerList.includes(member.id)) {
            curList.unshift(member);
          } else {
            curList.push(member);
          }
          return curList;
        },
        [],
      );
      setMemberList(sortedMemberList);
    };

    getMemberList();
  }, [currentOrg]);

  const handleContinueDynamicStream = (value: { followers: string[] }) => {
    const { followers } = value;
    modalConfirm({
      title: 'Xác nhận thêm follower?',
      async onOk() {
        const response: ResponseDataType<{ id: string }> = await postRequest(
          '/stream/add-follower-to-stream',
          {
            rootId,
            followers: followers || [],
          },
        );

        if (response.code) {
          if (typeof response.message === 'object') {
            response.message.forEach((message: string) => toast.error(message));
          } else toast.error(response.message);
        } else {
          toast.success('Thêm follower thành công');
          close();
        }
      },
    });
  };

  return (
    <Popup
      close={close}
      isHandleBlur={false}
      showCloseButton={false}
      customStyle={{
        contentBgrColor: '#fbfbfb',
        wrapperColor: '#fbfbfb',
        popupMargin: '50px auto auto auto',
      }}
    >
      <>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: 410,
            // marginBottom: 10,
          }}
        >
          <div style={{ fontWeight: 600, fontSize: 30, marginBottom: 30 }}>
            Thêm follower
          </div>
          <div onClick={close} style={{ height: 35, cursor: 'pointer' }}>
            <CloseOutlined sx={{ fontSize: 35 }} />
          </div>
        </div>
        <Form form={form} onFinish={handleContinueDynamicStream}>
          <Form.Item
            name='followers'
            rules={[
              {
                required: true,
                message: 'Cần chọn ít nhất 1 thành viên!',
              },
            ]}
            style={{ marginBottom: 0, width: 410 }}
          >
            <SelectMember
              mode='multiple'
              placeholder='Follower'
              optionFilterProp='label'
              showSearch={true}
              filterOption={(input, option) =>
                normalizeText(option?.label?.toString()).includes(
                  normalizeText(input),
                ) ||
                normalizeText(option?.key?.toString()).includes(
                  normalizeText(input),
                )
              }
              // style={{ height: 50 }}
            >
              {memberList.map((member, index) => {
                const memberPosition = getFullInfoInDepartment({
                  isOldInfo: false,
                  isManager: member.departmentInfo.isManager,
                  department: { ...member.departmentInfo },
                });

                return (
                  <Select.Option
                    key={`${index}-${memberPosition}`}
                    value={member.id}
                    label={member.name}
                  >
                    <Row>
                      <Avatar
                        src={member.avatar}
                        style={{ width: 30, height: 30, borderRadius: '50%' }}
                      />
                      <div style={{ lineHeight: 'normal', marginLeft: 10 }}>
                        <div>{member.name}</div>
                        <div style={{ fontSize: 11, fontWeight: 300 }}>
                          {memberPosition}
                        </div>
                      </div>
                    </Row>
                  </Select.Option>
                );
              })}
            </SelectMember>
          </Form.Item>
          <Button
            type='primary'
            className={popupStyles.form_button}
            style={{ width: 410, marginTop: 60 }}
            htmlType='submit'
          >
            Thêm
          </Button>
        </Form>
      </>
    </Popup>
  );
};

const SelectMember = styled(Select)`
  .ant-select-selector {
    min-height: 40px;
  }

  .ant-select-selection-item {
    height: max-content;
  }

  .ant-select-selection-item-content {
    height: max-content;
    padding: 3px 0;
    margin-inline-end: 10px !important;
  }
`;
