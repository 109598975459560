import { EndStreamType, NormalNotiType } from 'constant/enum';
import { ActionPropType, StreamActionPropType } from '..';
import { completeDynamicStream, updateApproveTaskValue } from './action';
import {
  ButtonBox,
  ButtonGreen,
  ButtonRed,
  DisableButtonGreenWrapper,
  DisableButtonRedWrapper,
  IconWrapper,
  Space,
} from './style';
import { useAppContext } from 'Context/AppContext';
import { DepartmentNamecode } from 'constant/department';
import { Row } from 'antd';
import { useStreamDetailInfo } from 'Pages/Stream/StreamDetail/Context/StreamDetailContext';
import { useState } from 'react';
import { ContinueDynamicStreamPopup } from '../ActionPopup';
import { Add, Clear, Done, DoneAll, MoreHoriz } from '@mui/icons-material';
import { useParams } from 'react-router-dom';

const ApproveDenyAction = (props: ActionPropType) => {
  const { streamId } = props;
  const { currentOrg } = useAppContext();

  return (
    <ButtonBox>
      <ButtonGreen
        onClick={() =>
          updateApproveTaskValue({
            value: 1,
            streamId,
            endStream: false,
            title: 'Xác nhận duyệt',
            currentOrg,
          })
        }
      >
        Duyệt
      </ButtonGreen>
      <Space />
      <ButtonRed
        onClick={() =>
          updateApproveTaskValue({
            value: 0,
            streamId,
            endStream: true,
            title: 'Xác nhận từ chối',
            currentOrg,
            endType: EndStreamType.REFUSE,
          })
        }
      >
        Từ chối
      </ButtonRed>
    </ButtonBox>
  );
};

export const ApproveTaskAction = (props: StreamActionPropType) => {
  const { isEnd, canUpdate, optionValue, ...rest } = props;

  if (optionValue === 1) {
    return <DisableButtonGreenWrapper label='Đã duyệt' />;
  }

  if (optionValue === 0) {
    return <DisableButtonRedWrapper label='Đã từ chối' />;
  }

  if (!canUpdate) {
    return <DisableButtonGreenWrapper label='Chờ duyệt' />;
  }

  return <ApproveDenyAction {...rest} />;
};

const ConfirmAction = (props: ActionPropType) => {
  const { streamId } = props;
  const { currentOrg, currentOrgInfo } = useAppContext();

  return (
    <ButtonBox>
      <ButtonGreen
        onClick={() =>
          updateApproveTaskValue({
            value: 1,
            streamId,
            endStream: false,
            title: 'Xác nhận duyệt?',
            currentOrg,
          })
        }
      >
        Xác nhận
      </ButtonGreen>
      <Space />
      {currentOrgInfo?.memberDepartmentCode === DepartmentNamecode.CEO && (
        <ButtonRed
          onClick={() =>
            updateApproveTaskValue({
              value: 0,
              streamId,
              endStream: true,
              title: 'Xác nhận hủy',
              currentOrg,
              endType: EndStreamType.CANCEL,
            })
          }
        >
          Hủy
        </ButtonRed>
      )}
    </ButtonBox>
  );
};

export const ConfirmTaskAction = (props: StreamActionPropType) => {
  const { isEnd, canUpdate, optionValue, ...rest } = props;

  if (optionValue === 1) {
    return <DisableButtonGreenWrapper label='Đã duyệt' />;
  }

  if (optionValue === 0) {
    return <DisableButtonRedWrapper label='Đã hủy' />;
  }

  if (!canUpdate) {
    return <DisableButtonGreenWrapper label='Chờ duyệt' />;
  }

  return <ConfirmAction {...rest} />;
};

const CompleteOrEndDynamicStream = (props: ActionPropType) => {
  const { streamId } = props;
  const { currentOrg } = useAppContext();

  return (
    <Row>
      <IconWrapper
        style={{ color: '#CACACA', borderColor: '#CACACA' }}
        onClick={() =>
          updateApproveTaskValue({
            value: 1,
            streamId,
            endStream: false,
            title: 'Xác nhận hoàn thành',
            currentOrg,
            notiType: NormalNotiType.EXECUTE_STREAM,
            allowUpdateEndStreamTask: true,
          })
        }
      >
        <Done style={{ fontSize: 27 }} />
      </IconWrapper>
      <Space />
      <IconWrapper
        style={{ color: '#CACACA', borderColor: '#CACACA' }}
        onClick={() =>
          updateApproveTaskValue({
            value: 0,
            streamId,
            endStream: false,
            title: 'Xác nhận từ chối',
            currentOrg,
            notiType: NormalNotiType.REJECT_STREAM,
            allowUpdateEndStreamTask: true,
          })
        }
      >
        <Clear style={{ fontSize: 27 }} />
      </IconWrapper>
    </Row>
  );
};

const ContinueButton = () => {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <>
      <Space />
      <IconWrapper
        style={{ color: '#CACACA', borderColor: '#CACACA' }}
        onClick={() => setShowPopup(true)}
      >
        <Add style={{ fontSize: 27 }} />
      </IconWrapper>
      {showPopup && (
        <ContinueDynamicStreamPopup close={() => setShowPopup(false)} />
      )}
    </>
  );
};

const EndButton = () => {
  const { streamId: rootId = '' } = useParams();

  return (
    <>
      <Space />
      <IconWrapper
        style={{ color: '#CACACA', borderColor: '#CACACA' }}
        onClick={() =>
          completeDynamicStream({
            rootId,
            title: 'Xác nhận kết thúc?',
          })
        }
      >
        <DoneAll style={{ fontSize: 27 }} />
      </IconWrapper>
    </>
  );
};

export const DynamicStreamAction = (props: StreamActionPropType) => {
  const { isStreamFollowEnd } = useStreamDetailInfo();
  const {
    isEnd,
    canUpdate,
    optionValue,
    isAssignee,
    isTheLastStream,
    ...rest
  } = props;

  if (optionValue === 1) {
    return (
      <Row>
        <IconWrapper
          disable
          style={{ backgroundColor: '#42b814', color: 'white' }}
        >
          <Done style={{ fontSize: 27 }} />
        </IconWrapper>
        {isAssignee && isTheLastStream && !isStreamFollowEnd && (
          <>
            <ContinueButton />
            <EndButton />
          </>
        )}
      </Row>
    );
  }

  if (optionValue === 0) {
    return (
      <IconWrapper
        disable
        style={{ backgroundColor: '#D04339', color: 'white' }}
      >
        <Clear style={{ fontSize: 27 }} />
      </IconWrapper>
    );
  }

  if (!canUpdate) {
    return (
      <IconWrapper
        disable
        style={{ backgroundColor: '#CEF1C0', color: 'white' }}
      >
        <MoreHoriz style={{ fontSize: 27 }} />
      </IconWrapper>
    );
  }

  return <CompleteOrEndDynamicStream {...rest} />;
};
