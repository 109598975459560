import { useState } from "react";
import { AddFollowerPopup } from "../ActionPopup";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";

export const AddNewFollower = () => {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <>
      <div
        style={{ cursor: "pointer", height: 30 }}
        onClick={() => setShowPopup(true)}
      >
        <PersonAddAltIcon style={{ fontSize: 30 }} />
      </div>
      {showPopup && <AddFollowerPopup close={() => setShowPopup(false)} />}
    </>
  );
};
