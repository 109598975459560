export enum StreamFollowerType {
  PROPOSAL = 1,
  REQUEST = 2,
}

export enum StreamType {
  PROPOSAL = 1,
  TASK = 2,
}

export enum ProposalType {
  DYNAMIC = 0,
  GENERAL = 3,
}

export enum RequestType {
  PAYMENT = 7,
  ADVANCE = 8,
  REIMBURSEMENT = 9,
}

export enum TaskType {
  DYNAMIC = 0,
  APPROVE_PROPOSAL = 2,
  PROCESS_PAYMENT = 10,
  EXECUTE_PROPOSAL = 11,
  CONFIRM_REQUEST = 12,
}

export enum MemberStatus {
  ACTIVE = 1,
  INACTIVE = 0,
  DEACTIVATE = 2,
  DELETED = 3,
}

export enum EndStreamType {
  REFUSE = 'refuse',
  CANCEL = 'cancel',
}

export enum EndStreamFollowerStatus {
  IN_PROGRESS = 0,
  END = 1,
}

export enum NormalNotiType {
  CREATE_STREAM = 1,
  APPROVE_STREAM = 2,
  REJECT_STREAM = 3,
  EXECUTE_STREAM = 4,
  SEND_COMMENT = 5,
  END_DYNAMIC_STREAM = 6,
  CONTINUE_DYNAMIC_STREAM = 7,
  ADD_FOLLOWER = 8,
}

// test acl
