import { AddCircleOutline } from '@mui/icons-material';
import { VerticalLine } from 'Components';
import { useState } from 'react';
import { ContinueDynamicStreamPopup } from '../ActionPopup';

export const AddNewDynamicStream = () => {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <div
      style={{
        marginLeft: 38,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 39,
      }}
    >
      <VerticalLine height='10px' />
      <div style={{ cursor: 'pointer' }} onClick={() => setShowPopup(true)}>
        <AddCircleOutline style={{ fontSize: 39 }} />
      </div>
      <VerticalLine height='50px' />
      {showPopup && (
        <ContinueDynamicStreamPopup close={() => setShowPopup(false)} />
      )}
    </div>
  );
};
